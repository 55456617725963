
import { defineComponent, onMounted, inject, watch, ref, reactive } from "vue";
import { calendarFormat} from "@/utils/dateFormat";


export default defineComponent({
  name: "BalanceItem",
  props: {
    myBalance: Object,
  },

  setup(props) {
    const balance = ref()
    watch(
        () => {
          return props.myBalance
        },
        (NewsVue) => {
          balance.value = (NewsVue as any).totalResult && (NewsVue as any).totalResult[0] && (NewsVue as any).totalResult[0].str
        }
    )
    return {
      calendarFormat,
      balance
    };
  }
});
