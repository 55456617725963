<template>
  <transition-group tag="ul" name="list" class="order-ul">
    <li v-for="item in myBalance.data" :key="item.id">
      <div class="order-date">账单编号：{{ item.id }}</div>
      <div class="order-date">
        <span v-if="item.savingtype == 1">预存金额：￥{{ Number(item.savingamount).toFixed(2) }}</span>
        <span v-if="item.savingtype == 2">消费金额：￥{{ Number(item.savingamount).toFixed(2) }}</span>
      </div>
      <div class="order-date">消费单号：{{ item.ono!='0'?item.ono : '/' }}</div>
      <div class="spend-date" style="top: 28px;">
        <span>{{ calendarFormat(item.savingdate)[2] }}</span>
        <p>
          {{ calendarFormat(item.savingdate)[0] }}/{{ calendarFormat(item.savingdate)[1] }}
        </p>
      </div>
    </li>
  </transition-group>
  <div class="balance" v-if="balance && myBalance && myBalance.data.length">
    余额：<span style="color: red"> ￥{{ Number(balance).toFixed(2) }}</span>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, inject, watch, ref, reactive } from "vue";
import { calendarFormat} from "@/utils/dateFormat";


export default defineComponent({
  name: "BalanceItem",
  props: {
    myBalance: Object,
  },

  setup(props) {
    const balance = ref()
    watch(
        () => {
          return props.myBalance
        },
        (NewsVue) => {
          balance.value = (NewsVue as any).totalResult && (NewsVue as any).totalResult[0] && (NewsVue as any).totalResult[0].str
        }
    )
    return {
      calendarFormat,
      balance
    };
  }
});
</script>


<style lang="scss" scoped>
main {
  padding-bottom: 110px;
}
.order-ul li {
  box-sizing: border-box;
  padding: 15px;
  padding-bottom: 15px;
  overflow: hidden;
  border-bottom: 5.6px solid #f4f4f4;
  position: relative;
  padding-left: 70px;
}

.spend-date {
  position: absolute;
  left: 15px;
  // top: 20px;
  top: 38px;
  color: #666;
}
.spend-date span {
  font-size: 22px;
  text-align: center;
  display: block;
}
.spend-date p {
  font-size: 14px;
  border-top: 1px solid #e5e5e5;
  padding-top: 5px;
}

.order-ul .title {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #eee;
  font-size: 15px;
  color: #444;
  position: relative;
}
.price {
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  line-height: 40px;
  color: rgb(102, 102, 102);
}
.order-date {
  margin: 0 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 0 5px 0;
  padding-left: 10px;
  font-size: 14px;
  box-sizing: border-box;
  color: #666;
  position: relative;
}

.order-btn {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -11.25px;
  text-align: center;
  width: 80px;
  height: 22.5px;
  border-radius: 15px;

  line-height: 22.5px;

  font-size: 14px;
  color: #df0034;
  border: solid 1px #df0024;
}

.balance {
  position: fixed;
  color: #666;
  bottom: 50px;
  left: 0;
  right: 0;
  height: 50px;
  line-height: 50px;
  padding-left: 25px;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
  font-size: 14px;
  font-weight: bold;
  background-color: #fff;
  z-index: 9;
}
</style>
